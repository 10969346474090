<template>
  <base-layout-two
    page-title="Edit Employer"
    :page-default-back-link="`/employers/${$route.params.id}`"
  >
    <div class="padding-8">
      <v-form @submit="onSubmit">
        <base-card title="Employer Details">
          <ion-grid>
            <ion-row>
              <!-- Name -->
              <ion-col size="12">
                <base-input label-text="Company Name *">
                  <v-field
                    name="name"
                    v-slot="{ field }"
                    v-model="employer.name"
                    :rules="required"
                  >
                    <ion-input
                      name="name"
                      v-bind="field"
                      placeholder="Amazon"
                      type="text"
                      autocapitalize="words"
                      autocorrect
                    >
                    </ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="name" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Offer -->
              <ion-col size="12">
                <base-input label-text="Offer *">
                  <v-field
                    name="offer"
                    v-slot="{ field }"
                    v-model="employer.offer"
                    :rules="required"
                  >
                    <ion-textarea
                      :auto-grow="true"
                      rows="3"
                      v-bind="field"
                      placeholder="(i.e. up to 40% off stays)"
                      autocapitalize
                    >
                    </ion-textarea>
                  </v-field>
                </base-input>
                <v-error-message name="offer" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Contact -->
              <ion-col size="12">
                <base-input label-text="Contact Person">
                  <v-field
                    name="contact"
                    v-slot="{ field }"
                    v-model="employer.contact"
                  >
                    <ion-input
                      name="contact"
                      v-bind="field"
                      placeholder=""
                      type="text"
                      autocapitalize="words"
                      autocorrect
                    >
                    </ion-input>
                  </v-field>
                </base-input>
              </ion-col>

              <!-- URL -->
              <ion-col size="12">
                <base-input label-text="Boram URL for Employer">
                  <v-field
                    name="url"
                    v-slot="{ field }"
                    v-model="employer.url"
                  >
                    <ion-input
                      name="url"
                      v-bind="field"
                      placeholder=""
                      type="text"
                    >
                    </ion-input>
                  </v-field>
                </base-input>
              </ion-col>

              <!-- Short URL -->
              <ion-col size="12">
                <base-input label-text="Short Version of URL">
                  <v-field
                    name="url_short"
                    v-slot="{ field }"
                    v-model="employer.url_short"
                  >
                    <ion-input
                      name="url_short"
                      v-bind="field"
                      placeholder=""
                      type="text"
                    >
                    </ion-input>
                  </v-field>
                </base-input>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button type="submit" expand="block" class="margin-y-16">
            Save
          </ion-button>
        </base-card>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonInput, IonButton } from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      employer: {},
      required: yup.string().required(),
    };
  },

  ionViewWillEnter() {
    this.fetchEmployer();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchEmployer() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/employers/${this.$route.params.id}`)
        .then((response) => {
          this.employer = response.data.employer;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async onSubmit() {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/employers/${this.$route.params.id}`,
        data: this.employer,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.employer = response.data.employer;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Employer Updated", color: "secondary" });

          this.$router.replace(`/employers/${this.employer.id}`);
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>